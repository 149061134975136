import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Portfolio Website Template | Portfolio Web Design | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/portfolio-web-app/"
    metaDescription="Looking to design a portfolio website? Uizard's new portfolio website design template is the perfect way to fast-track your design flow."
    description="
    h2:A portfolio website design template for your next project
    <br/>
    Looking to design a portfolio website to showcase your skills and experience? Uizard’s new portfolio website design template is the perfect way to fast-track your project. Simply sign up to Uizard Pro and navigate to our <a:https://uizard.io/templates/>UI design templates</a> library to get started. Our templates come with all the must-have interactions build in, meaning you can go from idea to prototype in no time at all.
    <br/>
    h3:Bring your vision to life with the power of AI
    <br/>
    Bring your portfolio website design idea to life by combining this stunning template with Uizard’s <a:https://uizard.io/ai-design/>AI design</a> features. Uizard AI empowers you to update your designs or start brand-new ones from scratch with ease. You can convert screenshots into editable designs with the click of a button, upload your hand-drawn wireframes, and you can even use Text Assistant to generate placeholder copy.
    <br/>
    h3:Work rapidly and collaboratively with Uizard
    <br/>
    Work solo or invite your colleagues to collaborate in real-time; team working has never been so simple and streamlined. Looking to get key feedback on your project from external stakeholders or potential customers? Share your <a:https://uizard.io/ux-design/>UX design</a> project link with the click of a button.
    "
    pages={[
      "Portfolio landing page mockup with placeholder user details to demonstrate usage",
      "Mockup of portfolio example screens to showcase a user's work",
      "Portfolio contact details screen outlining how users will provide their personal details",
    ]}
    projectCode="JJA7Wy9vGMhmbYMw0RMd"
    img1={data.image1.childImageSharp}
    img1alt="Freelance portfolio website cover"
    img2={data.image2.childImageSharp}
    img2alt="Freelance portfolio mockup example work page"
    img3={data.image3.childImageSharp}
    img3alt="Freelance portfolio example detailed work page"
    img4={data.image4.childImageSharp}
    img4alt="Freelance portfolio example contact page"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of freelance portfolio web app screens"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/portfolio-web-app/freelancer-portfolio-web-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/portfolio-web-app/freelancer-portfolio-web-overview.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/portfolio-web-app/freelancer-portfolio-web-project-detail.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/portfolio-web-app/freelancer-portfolio-web-contact.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/portfolio-web-app/freelancer-portfolio-web-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
